import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  ChatBot32,
  Pin32,
  DeliveryParcel32,
  Tools32,
  Purchase32,
  ArrowDown32,
  Mobile32,
  Tablet32,
  Headset32,
  Screen32,
  GameConsole32,
  WatsonHealthAnnotationVisibility32,
  EventSchedule32,
} from "@carbon/icons-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledBackgroundSection from "../components/backgroundImage"
import styled from "styled-components"

const HowItWorks = styled.section`
  @media (min-width: 800px) {
    margin-top: 144px;
  }
  margin-top: 444px;
  margin-bottom: 4rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  h2 {
    text-align: center;
    margin: auto;
    border-bottom: 2px solid #0043ce;
    width: 75%;
  }
  ul {
    padding: 0.4rem 0;
    background-color: white;
    color: black;
    border-radius: 4px;
    list-style: none;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    li {
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }
`
const Services = styled.section`
  padding: 2rem 0;
  background-color: #000000;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  h2 {
    text-align: center;
    margin: auto;
    border-bottom: 2px solid #0043ce;
    width: 75%;
  }
  ul {
    @media (min-width: 800px) {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 66%;
      min-height: 22rem;
    }
    list-style: none;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0.4rem;
    gap: 0.4rem;
    li {
      box-sizing: border-box;
      border: 2px solid #0043ce;
      text-align: center;
      margin: 0;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0;
    }
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <StyledBackgroundSection />
    <HowItWorks>
      <h2>How It Works</h2>
      <ul>
        <li>
          <ChatBot32 />
          Free Estimation
        </li>
        <li>
          <ArrowDown32 />
        </li>
        <li>
          <Pin32 /> Parts Reservation
        </li>
        <li>
          <ArrowDown32 />
        </li>
        <li>
          <DeliveryParcel32 />
          Drop Off
        </li>
        <li>
          <ArrowDown32 />
        </li>
        <li>
          <Tools32 />
          Repair
        </li>
        <li>
          <ArrowDown32 />
        </li>
        <li>
          <Purchase32 />
          Pay and Go
        </li>
      </ul>
    </HowItWorks>

    <Services>
      <h2>Other Services</h2>
      <ul>
        <li>
          <WatsonHealthAnnotationVisibility32 />
          Diagnostic
        </li>
        <li>
          <Mobile32 />
          Phone Repair
        </li>
        <li>
          <Tablet32 />
          Tablet Repair
        </li>
        <li>
          <Headset32 />
          Software Support
        </li>
        <li>
          <Screen32 />
          Computer Repair
        </li>
        <li>
          <GameConsole32 />
          Game Console Repair
        </li>
      </ul>
    </Services>
    <section>
      <p style={{ padding: "1rem", textAlign: "center" }}>
        <EventSchedule32 style={{ display: "block", margin: "auto" }} />
        Store open from 10:am to 10:pm.
      </p>
    </section>
  </Layout>
)

export default IndexPage
